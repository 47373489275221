<template>
  <div class="fluid-container footer">
    <nav class="navbar navbar-dark text-white bg-primary">
      <!-- <nav class="p-3 mb-2 bg-info text-white"> -->
      <a
        href="https://www.jetdock.com/boat-lifts/"
        class="nav-link nav-item active text-white"
      >Boat Lifts</a>
      <a
        href="https://www.jetdock.com/pwc-lifts/"
        class="nav-link nav-item active text-white"
      >PWC Lifts</a>
      <a
        href="http://www.bestkayakdocks.com//"
        class="nav-link nav-item active text-white"
      >Kayak Lifts</a>
      <a
        href="https://www.jetdock.com/floating-docks/"
        class="nav-link nav-item active text-white"
      >Walkway</a>
      <a
        href="https://www.jetdock.com/seaplane-docks/"
        class="nav-link nav-item active text-white"
      >Seaplane</a>
      <a
        href="https://www.jetdock.com/warranty.asp"
        class="nav-link nav-item active text-white"
      >JetDock Warranty</a>
    </nav>
    <!-- <footer> -->
    <footer class="fluid-container footer bg-primary">
      Copyright &copy; {{ new Date().getFullYear() }} Jet Dock Wisconsin, LLC
    </footer>
  </div>
</template>

<script>
export default {
    name: 'Footercomponent',
};
</script>

<style>
    .fluid-container.footer{
  /* background: rgb(74, 169, 233); */
  background: #0dcaf0;
  /* padding: 20px; */
}
.fluid-container.footer > *:last-child {
    margin-bottom: 0px;
    color: #fff;
}
</style>