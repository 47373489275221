<template>
  <div
    class="container-fluid bg-image"
    :style="bgi"
  >
    <!-- <div > -->
    <img
      alt="Jet Dock Wisconsin"
      src="./assets/jdwi1.webp"
      class="img-fluid p-5"
    >
    <!-- <div id="nav" fixed="top"> -->
    <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top bg-gradient-info "> -->
    <nav class="fixed-top navbar navbar-dark text-white bg-primary">
      <!-- <a class="navbar-brand" href="#">Navbar</a> -->
      <div class="nav-item">
        <router-link
          to="/"
          class="nav-link nav-item active text-white"
        >
          Jet Dock Wisconsin
        </router-link>
      </div> |
      <div class="nav-item">
        <router-link
          to="/LocalInstalls"
          class="nav-link nav-item active text-white"
        >
          Local Installs
        </router-link>
      </div> |
      <div class="nav-item dropdown">
        <div
          id="navbarDropdown"
          class="nav-link dropdown-toggle text-white"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Lifts
        </div>
        <div
          class="dropdown-menu"
          aria-labelledby="navbarDropdown"
        >
          <li>
            <router-link
              to="/pwclifts"
              class="nav-link nav-item active dropdown-item"
            >
              PWC Lifts
            </router-link>
          </li>
          <li>
            <router-link
              to="/boatlifts"
              class="nav-link nav-item active dropdown-item"
            >
              Boat Lifts
            </router-link>
          </li>
          <!-- <li><hr class="dropdown-divider"></li> -->
          <!-- <li><a class="dropdow-item" href="#">Something else here</a></li> -->
        </div>
      </div> |
      <!-- <router-link to="/lifts" class="nav-link nav-item active text-white">Lifts</router-link> | -->
      <!-- <router-link to="/docks" class="nav-link nav-item active text-white">Docks</router-link> |  -->
      <!-- <router-link to="/accessories" class="nav-link nav-item active text-white">Accesories</router-link> |  -->
      <router-link
        to="/contactform"
        class="nav-link nav-item active text-white"
      >
        Contact
      </router-link> |
      <router-link
        to="/about"
        class="nav-link nav-item active text-white"
      >
        About
      </router-link>
    </nav>
     
    <!-- </div> -->
    <router-view />
  
    
    <!-- <HelloWorld /> -->
    <!-- <ContactForm/> -->
    <!-- <cform2 /> -->
    <footerfile />
  </div>
</template>
<script>
// import HelloWorld from "./components/jetdockwi.vue";
import footerfile from "./components/footerfile.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
// import ContactForm from './components/contactform.vue'
// import cform2 from "./views/cform2.vue";

export default {
  name: "App",
  components: {
    // HelloWorld,
    footerfile,
    // ContactForm,
    // cform2,
  },
  data () {
    return {
        toggle: true,
        bgi: {backgroundImage: "url(https://dsm01pap006files.storage.live.com/y4mg6VEDVFAHjCt4CPMtOA-Oydq5850OLjIIgIjv5B6UoTRPBRZ94Jt5XxcywPtncKqbO-ahX1QzZScNhfAcSbLMskwwMzSMmbNKV9h5i2cdkGwXElPRzCtWTA21KvVenBlEEuENh3xPyY-k0ujYjbD8TKd0mxuKla5u0XodHkfRckFkrjCfHi5YAhxj_VOP8tJ?width=1440&height=1080&cropmode=none)"}
    }
  },
 
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
/* .router-link{
    
} */
</style>
